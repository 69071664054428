import router from '../../plugins/router'
import SCard from '../../components/ui/s-card'
import { mapState } from 'vuex'

export default {
  name: 'IssueCard',
  props: ['issue'],

  components: {
    SCard,
  },

  computed: {
    ...mapState(['company']),
    awaiting() {
      return this.issue.status == 'ANSWERED' && !this.issue.completed
    },
  },

  methods: {
    openIssue() {
      router.push({
        name: 'conversation',
        params: {
          code: this.issue.protocol,
          issueId: this.issue.id,
        },
      })
    },
  },
}
